<template>
  <v-dialog
    v-model="visible"
    scrollable
    max-width="750px"
    hide-overlay
    persistent
    no-click-animation
  >
    <v-card
      v-if="lineItemRecord"
      ref="draggableModal"
      class="draggable-dialog"
      max-height="60%"
      max-width="70%"
      @mousedown="dragStart"
      @mousemove="dragging"
    >
      <v-card-title>
        <v-row class="px-3 py-2">
          <div>
            <div class="darkblue--text subtitle-2">
              {{ lineItemRecord.lineItemName }}
            </div>

            <div class="coolgray--text caption">
              <span class="font-weight-bold pr-1">ID</span>
              {{ lineItemRecord.lineItemId }}
            </div>
          </div>

          <v-spacer />

          <div>
            <v-btn
              class="px-3 mx-3"
              color="coolgray"
              icon
              :ripple="false"
              title="Export"
              @click.stop="exportReport(lineItemRecord)"
            >
              <v-icon>
                system_update
              </v-icon>
            </v-btn>

            <v-btn
              :ripple="false"
              icon
              @click.stop="close()"
            >
              <v-icon color="midgray">
                close
              </v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text>
        <div
          v-if="isLoadingCreativesReport"
          class="loader"
        >
          <v-progress-circular
            :size="70"
            color="#055289"
            indeterminate
          />
        </div>

        <div
          v-else-if="hasError"
          class="error-message"
        >
          <v-icon>error_outline</v-icon>
          Failed to load Creatives Report. Please try again later.
        </div>

        <div
          v-else-if="hasZeroRows"
          class="empty-state"
        >
          Creative data could not be found for selected line item.
        </div>

        <div
          v-else
          class="creatives"
        >
          <v-card
            v-for="creativeGroup of creativesReport"
            :key="creativeGroup.creativeCode"
            class="creative pa-3"
            flat
          >
            <v-card-title class="creativeName subtitle-2 pa-0">
              <v-row>
                <v-col>
                  <div>
                    {{ creativeGroup.creativeName }}
                  </div>

                  <div class="gray--text">
                    <span class="font-weight-bold pr-1">Size</span>
                    <span class="font-weight-regular">
                      {{ creativeGroup.creativeSize || '0x0' }}
                    </span>
                  </div>
                </v-col>

                <v-col
                  class="gray--text font-weight-regular col-3 shrink text-right"
                >
                  {{ creativeGroup.creativeCode }}
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text class="creative-table d-flex">
              <table v-if="creativeGroup.rows && creativeGroup.rows.length">
                <thead>
                  <th
                    v-for="column in reportColumns"
                    :key="column.columnName"
                    :class="`${column.class} py-2`"
                  >
                    {{ column.columnName }}
                  </th>
                </thead>

                <tbody>
                  <tr
                    v-for="creativeItem of creativeGroup.rows"
                    :key="creativeItem.creativeDeliveryId"
                  >
                    <td
                      v-for="column in reportColumns"
                      :key="column.columnName"
                      :class="column.class"
                    >
                      {{ formatAs(column, creativeItem, creativeGroup.rows) }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                v-else
                class="pt-1"
              >
                This creative has no delivery data.
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import draggable from 'src/utils/draggable'
import format from 'src/utils/format'
import osi from '../../store/utils/osi'

const { mapState, mapActions } = createNamespacedHelpers('delivery')
const {
  mapActions: mapToolsActions,
  mapGetters: mapToolsGetters,
  mapState: mapToolsState,
} = createNamespacedHelpers('tools')

export default {
  name: 'CreativesReportModal',
  props: {
    lineItemRecord: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    osi,
    dragger: {},
    exportFileName: 'creatives',
  }),
  computed: {
    visible: {
      get() {
        return this.isCreativesReportModalVisible
      },
      set(value) {
        this.$store.commit('delivery/isCreativesReportModalVisible', value)
      },
    },
    ...mapToolsGetters(['storedItems']),
    ...mapState({
      columns: (state) => state.columns,
      isCreativesReportModalVisible: (state) => state.isCreativesReportModalVisible,
      isLoadingCreativesReport: (state) => state.isLoadingCreativesReport,
      didLoadCreativesReportFail: (state) => state.didLoadCreativesReportFail,
      creativesReport: (state) => state.creativesReport,
    }),
    ...mapToolsState({
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
    hasZeroRows() {
      const { creativesReport } = this
      const { length } = Object.keys(creativesReport)
      return creativesReport && length === 0
    },
    hasError() {
      const { creativesReport, didLoadCreativesReportFail } = this
      const invalidReport = creativesReport && typeof creativesReport !== 'object'

      return didLoadCreativesReportFail || invalidReport
    },

    viewCreatives() {
      const {
        creativesReport,
        didLoadCreativesReportFail,
        isLoadingCreativesReport,
      } = this

      return isLoadingCreativesReport === false
        && didLoadCreativesReportFail === false
        && creativesReport.creativeName
    },
    exportColumns() {
      return this.columns.creativesReport
    },
    reportColumns() {
      return this.columns.creativesReport.filter((column) => !column.exportOnly)
    },
  },
  watch: {
    lineItemRecord(newValue) {
      if (newValue) {
        const { reportId } = this
        const params = { ...newValue, publisherId: reportId }

        this.getCreativesReport(params)
      }
    },
  },
  created() {
    document.addEventListener('mouseup', this.finishDragging)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.finishDragging)
  },
  methods: {
    close() {
      this.visible = false
    },
    ...mapToolsActions(['exportAs']),
    ...mapActions(['getCreativesReport']),
    ...draggable,
    formatAs(column, creativeItem, groupItems) {
      const { storedItems } = this

      return format.formatColumn(column, creativeItem, groupItems, storedItems)
    },
    exportReport(lineItemRecord) {
      const { lineItemId } = lineItemRecord
      const { exportColumns, creativesReport, exportFileName, storedItems: exportItems } = this
      const exportGroups = Object.keys(creativesReport).map((groupKey) => creativesReport[groupKey])
      const exportName = `${exportFileName}_for_${lineItemId}`
      const exportFormat = 'csv'

      return this.exportAs({ exportColumns, exportFormat, exportGroups, exportName, exportItems })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~src/styles/variables";

  .draggable-dialog {
    border-radius: 6px;
    border-width: 0;
    left: 15%;
    position: fixed;
    top: 69px;
  }
  ::v-deep.v-card__title {
    color: $color-monochrome-darkestblue;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.51px;
    line-height: 22px;
    justify-content: space-between;
  }

  ::v-deep.v-card__text {
    padding: 0;
  }

  table {
    border: 1px solid #F2EFEF;
    border-spacing: 0;
    width: 100%;
  }

  th {
    top: 0px;
    position: sticky;
    position: -webkit-sticky;
    text-transform: uppercase;
    border-bottom: 1px solid #F2EFEF;
    background: $color-monochrome-white;

    color: #515151;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.21px;
    line-height: 11px;

    text-align: left;
    padding-right: 8px;

    &:first-of-type {
      padding-left: 24px;
    }
  }

  tbody tr {
    height: 60px;

    &:nth-child(odd) {
      background-color: #FBFBFC;
    }
  }

  td {
    color: $color-monochrome-gray;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    line-height: 13px;
    padding-right: 24px;

    &:first-of-type {
      padding-left: 24px;
    }
  }

  td.osi {
    font-weight: bold;

    &.lte-96 {
      color: $color-legend-red;
    }

    &.range-97-99 {
      color: $color-legend-yellow;
    }

    &.range-100-125 {
      color: $color-legend-green;
    }

    &.gte-126 {
      color: $color-legend-blue;
    }
  }
</style>
